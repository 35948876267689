/* eslint no-use-before-define: "off" */
/* global _LTracker */

export { log, stringifyError };

const log = (eventType, eventDetails) => {
  if (typeof _LTracker !== 'undefined') {
    _LTracker.push({
      EventType: eventType,
      ...eventDetails,
    });
  }
};

const stringifyError = (err, filter, space) => {
  const plainObject = {};
  Object.getOwnPropertyNames(err).forEach(key => {
    plainObject[key] = err[key];
  });
  return JSON.stringify(plainObject, filter, space);
};
