/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

export { colourFromScore };

const colourFromScore = score => {
  if (score === null) {
    return 'grey';
  }
  if (score < 50) {
    return 'red';
  }
  if (score < 90) {
    return 'orange';
  }
  return 'blue';
};
