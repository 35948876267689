/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

import Cookies from 'js-cookie';

import { isNull, isNullOrUndefined } from 'common/utility';
import timezones from 'common/timezones.json';

export { getHubSpotBody };

const getHubSpotBody = async (
  firstName,
  lastName,
  emailAddress,
  facebookPage,
  timezone,
  location,
  document
) => {
  const ipResponse = await fetch('https://ipinfo.io?token=41f0a8e001480b');
  const ipData = await ipResponse.json();
  let hubSpotTz = '';
  try {
    hubSpotTz = Object.keys(timezones).filter(
      tz => timezones[tz] === timezone
    )[0];
  } catch (error) {
    //
  }
  const hubSpotBody = {
    context: {
      ipAddress: ipData.ip,
      pageUri: location.href,
      pageName: 'Facebook Grader',
    },
    fields: [
      { name: 'firstname', value: firstName },
      { name: 'lastname', value: lastName },
      { name: 'email', value: emailAddress },
      {
        name: 'facebook_grader_url',
        value: facebookPage,
      },
      {
        name: 'timezone_of_your_facebook_page',
        value: hubSpotTz ? `${hubSpotTz}: ${timezones[hubSpotTz]}` : '',
      },
    ],
  };

  const hubSpotUtk = Cookies.get('hubspotutk');
  if (!isNullOrUndefined(hubSpotUtk)) {
    hubSpotBody.context.hutk = hubSpotUtk;
  }

  const utmFields = [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ];
  utmFields.forEach(utmField => {
    const selector = `input[name='${utmField}']`;
    const element = document.querySelector(selector);
    if (!isNull(element)) {
      hubSpotBody.fields.push({
        name: utmField,
        value: element.value,
      });
    } else {
      const utmValue = Cookies.get(`__ft_${utmField}`);
      if (!isNullOrUndefined(utmValue)) {
        hubSpotBody.fields.push({
          name: utmField,
          value: utmValue,
        });
      }
    }
  });

  return JSON.stringify(hubSpotBody);
};
