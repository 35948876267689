/* eslint import/prefer-default-export: "off" */

export { isFacebookPageURL };

function isFacebookPageURL(url) {
  try {
    // Strip off protocol prefix
    const path = url.replace(/https?:\/\/(.*)/gi, '$1');
    // Get domain and ensure it is a valid Facebook domain name
    const domain = path.split('/')[0];
    if (
      domain !== 'facebook.com' &&
      domain !== 'm.facebook.com' &&
      domain !== 'www.facebook.com'
    ) {
      return false;
    }
    // Get remainder of url
    // Strip off query and fragment parameters and trailing slash
    const rest = path
      .split('?')[0]
      .split('#')[0]
      .replace(/(.*)\/$/gi, '$1');
    // Ensure path only contains the domain and one other element
    return rest.split('/').length === 2;
  } catch (e) {
    return false;
  }
}
