import React from 'react';
import PropTypes from 'prop-types';

import { colourFromScore } from 'common/misc';

const ScoreBar = props => {
  const { title, score } = props;

  const colour = colourFromScore(score);

  return (
    <>
      <div className="score-bar__title">
        <div>{title}</div>
        <div>
          <span>{score !== null ? score : ''}</span>
          <span className="score-bar__100">
            {score !== null ? '/100' : 'Unknown'}
          </span>
        </div>
      </div>
      <div className={`score-bar__background score-bar__background--${colour}`}>
        <div
          className={`score-bar__progress score-bar__progress--${colour}`}
          style={{ width: `${score}%` }}
        />
      </div>
    </>
  );
};

ScoreBar.propTypes = {
  title: PropTypes.string.isRequired,
  score: PropTypes.number,
};

ScoreBar.defaultProps = {
  score: null,
};

export default ScoreBar;
