import React from 'react';
import PropTypes from 'prop-types';
import TimezonePicker from 'react-bootstrap-timezone-picker';

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import FieldError from 'components/request/FieldError';

import timezones from 'common/timezones.json';

const Timezones = props => {
  const { timezone, timezoneError, handleTimezoneChange } = props;

  return (
    <label htmlFor="timezone">
      <div className="card-form-input__label-dual">
        <span className="timezone">Timezone of your Facebook Page</span>
        <div className="card-form-input__note" style={{ maxWidth: '240px' }}>
          Needed for your report to be accurate
        </div>
      </div>
      <TimezonePicker
        absolute={false}
        className={timezoneError !== '' ? 'card-form-input__error' : ''}
        data-reactid=".hbspt-forms-0.1:$3.1:$timezone_of_your_facebook_page.$timezone_of_your_facebook_page.0"
        onChange={handleTimezoneChange}
        placeholder="Select the timezone of your Facebook Page..."
        timezones={timezones}
        value={timezone}
      />
      <FieldError errorMessage={timezoneError} />
    </label>
  );
};

Timezones.propTypes = {
  timezone: PropTypes.string.isRequired,
  timezoneError: PropTypes.string.isRequired,
  handleTimezoneChange: PropTypes.func.isRequired,
};

export default Timezones;
