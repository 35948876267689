/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

export { getServerAddress };

const getServerAddress = () => {
  try {
    switch (window.location.hostname) {
      case 'facebookgrader-stage.ebx.sh':
      case 'ebx-facebook-grader-v1-stage.eba-skgp224c.eu-west-1.elasticbeanstalk.com':
      case 'facebookgrader.echobox.com':
      case 'facebook-grader.echobox.com':
      case 'ebx-facebook-grader-v1-prod.eba-skgp224c.eu-west-1.elasticbeanstalk.com':
        return `https://${window.location.hostname}`;
      default:
        return 'http://localhost:9000';
    }
  } catch (error) {
    return 'http://localhost:9000';
  }
};
