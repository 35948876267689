import React from 'react';

import analyzingSuccess from 'assets/img/img-analyzing-success.svg';

const Success = () => (
  <div className="card card-form-input">
    <div className="">
      <div className="">
        <h2>Great! We&apos;re analyzing your Facebook Page</h2>
        <p>
          It can take up to 3 hours to complete your Facebook Grader report.
        </p>
        <p>
          Once it&apos;s ready, we&apos;ll email you a link to your personalized
          report. Please check your spam folder if you haven&apos;t received
          your report after 3 hours.
        </p>
        <p>
          While you wait, check out our other{' '}
          <a
            href="https://www.echobox.com/resources/"
            target="_blank"
            rel="noopener noreferrer"
          >
            resources for publishers
          </a>
        </p>
        <img
          src={analyzingSuccess}
          alt=""
          className="card-form-input__img-success"
        />
      </div>
    </div>
  </div>
);

export default Success;
