import React from 'react';
import PropTypes from 'prop-types';

import { getDateFromUnix } from 'common/datetime';
import { isNull } from 'common/utility';

const Peak = props => {
  const { from, to } = props;

  const fromDate = !isNull(from) ? getDateFromUnix(from.timestamp) : null;
  const toDate = !isNull(to) ? getDateFromUnix(to.timestamp) : null;

  // Peak times are 05:35 to 06:35 and 15:00 to 16:00
  let peakStart;
  let peakEnd;
  if (isNull(fromDate)) {
    if (toDate.hour >= 6 && toDate.hour <= 15) {
      peakStart = '5.35am';
      peakEnd = '6.35am';
    } else {
      peakStart = '3pm';
      peakEnd = '4pm';
    }
  } else if (isNull(toDate)) {
    if (fromDate.hour <= 15) {
      peakStart = '5.35am';
      peakEnd = '6.35am';
    } else {
      peakStart = '3pm';
      peakEnd = '4pm';
    }
  } else if (fromDate.day === toDate.day) {
    if (fromDate.hour <= 5 && toDate.hour >= 6) {
      peakStart = '5.35am';
      peakEnd = '6.35am';
    } else {
      peakStart = '3pm';
      peakEnd = '4pm';
    }
  } else if (fromDate.hour > 6 || toDate.hour < 15) {
    peakStart = '5.35am';
    peakEnd = '6.35am';
  } else {
    peakStart = '3pm';
    peakEnd = '4pm';
  }

  /* jscpd:ignore-start */
  return (
    <div className="share-timing-clashes__clash">
      <img src="" alt="" />
      <div className="share-timing-clashes__clash-text">
        <span>{peakStart}</span>
        <div className="share-timing-clashes__clash-warning">
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H19L9.5 0L0 16ZM10.3636 13.4737H8.63636V11.7895H10.3636V13.4737ZM10.3636 10.1053H8.63636V6.73684H10.3636V10.1053Z"
              fill="#F5134A"
            />
          </svg>
          <span>Peak time missed</span>
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H19L9.5 0L0 16ZM10.3636 13.4737H8.63636V11.7895H10.3636V13.4737ZM10.3636 10.1053H8.63636V6.73684H10.3636V10.1053Z"
              fill="#F5134A"
            />
          </svg>
        </div>
        <span>{peakEnd}</span>
      </div>
      <img src="" alt="" />
    </div>
  );
  /* jscpd:ignore-end */
};

Peak.propTypes = {
  from: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    socialPostURL: PropTypes.string.isRequired,
    shareMessage: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }),
  to: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    socialPostURL: PropTypes.string.isRequired,
    shareMessage: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }),
};

Peak.defaultProps = {
  to: null,
  from: null,
};

export default Peak;
