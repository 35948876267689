/* eslint no-useless-escape: "off" */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as validator from 'email-validator';

import FieldError from 'components/request/FieldError';
import Timezones from 'components/request/Timezones';

import { getHubSpotBody } from 'common/hubspot';
import { getServerAddress } from 'common/environment';
import { isFacebookPageURL } from 'common/url';
import * as logger from 'common/logger';

import { HUBSPOT_URL } from 'common/constants';

const Form = props => {
  const { handleFormSuccess } = props;

  let initialTimezone = 'Etc/GMT';
  try {
    initialTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    //
  }

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [facebookPage, setFacebookPage] = useState('');
  const [facebookPageError, setFacebookPageError] = useState('');
  const [timezone, setTimezone] = useState(initialTimezone);
  const [timezoneError, setTimezoneError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('firstname').focus();
    }, 100);
  }, []);

  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
    setFirstNameError('');
    setGeneralError('');
  };

  const handleLastNameChange = event => {
    setLastName(event.target.value);
    setLastNameError('');
    setGeneralError('');
  };

  const handleEmailAddressChange = event => {
    setEmailAddress(event.target.value);
    setEmailAddressError('');
    setGeneralError('');
  };

  const handleFacebookPageChange = event => {
    setFacebookPage(event.target.value);
    setFacebookPageError('');
    setGeneralError('');
  };

  const handleTimezoneChange = value => {
    setTimezone(value);
    setTimezoneError('');
    setGeneralError('');
  };

  const handleSubmit = async () => {
    setGeneralError('');
    setSubmitting(true);
    const isValid = validateForm();
    if (!isValid) {
      setSubmitting(false);
      return;
    }
    try {
      const requestBody = JSON.stringify({
        firstName,
        lastName,
        emailAddress,
        facebookPage,
        timezone,
      });
      const formResponse = await fetch(getServerAddress(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: requestBody,
      });
      // Request accepted successfully
      if (formResponse.status === 200) {
        handleFormSuccess();
        // Submit form to HubSpot
        try {
          const hubSpotBody = await getHubSpotBody(
            firstName,
            lastName,
            emailAddress,
            facebookPage,
            timezone,
            window.location,
            document
          );
          await fetch(HUBSPOT_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: hubSpotBody,
          });
        } catch (error) {
          console.log(error);
        }
        return;
      }
      // Validation error
      if (formResponse.status === 422) {
        const formBody = await formResponse.json();
        if (formBody.firstNameError) {
          setFirstNameError(formBody.firstNameError);
        }
        if (formBody.lastNameError) {
          setLastNameError(formBody.lastNameError);
        }
        if (formBody.emailAddressError) {
          setEmailAddressError(formBody.emailAddressError);
        }
        if (formBody.facebookPageError) {
          setFacebookPageError(formBody.facebookPageError);
        }
        if (formBody.timezoneError) {
          setTimezoneError(formBody.timezoneError);
        }
        setSubmitting(false);
        return;
      }
      // Other error
      logger.log('Form Submission Error', {
        RequestDetails: JSON.stringify(requestBody),
        FormResponse: logger.stringifyError(formResponse),
      });
      setGeneralError('Error submitting request, please try again');
      setSubmitting(false);
    } catch (error) {
      logger.log('Form Submission Error', {
        ErrorDetails: logger.stringifyError(error),
      });
      setGeneralError('Error submitting request, please try again');
      setSubmitting(false);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (firstName.trim() === '') {
      isValid = false;
      setFirstNameError('Please add your first name');
    }
    if (lastName.trim() === '') {
      isValid = false;
      setLastNameError('Please add your last name');
    }
    if (emailAddress.trim() === '') {
      isValid = false;
      setEmailAddressError('Please add your company email address');
    }
    if (emailAddress.trim() !== '' && !validator.validate(emailAddress)) {
      isValid = false;
      setEmailAddressError('Please add a valid company email address');
    }
    const invalidDomains = 'gmail,yahoo,hotmail,aol,msn,wanadoo,orange,live,gmx,free,mail,t-online,icloud,mac'.split(
      ','
    );
    invalidDomains.forEach(domain => {
      const regex = new RegExp(`.+@${domain}\\..+`, 'gi');
      if (emailAddress.trim().match(regex)) {
        isValid = false;
        setEmailAddressError('Please add a valid company email address');
      }
    });
    if (facebookPage.trim() === '') {
      isValid = false;
      setFacebookPageError('Please add the full URL of your Facebook Page');
    }
    if (!isFacebookPageURL(facebookPage)) {
      isValid = false;
      setFacebookPageError('Please add a valid Facebook Page URL');
    }
    if (timezone === '') {
      isValid = false;
      setTimezoneError('Please select the timezone of your Facebook Page');
    }

    return isValid;
  };

  return (
    <div className="card card-form-input">
      <div>
        <h2>How healthy is your Facebook Page?</h2>
        <p>
          Is Facebook penalizing your posts? Are you sharing at peak times? How
          much traffic are you missing out on? Find out with a free personalized
          report.
        </p>
      </div>
      <form>
        <div className="card-form-input__form">
          <div className="card-form-input__split">
            <div>
              <label htmlFor="firstname">
                <span className="">First Name</span>
                <input
                  className={
                    firstNameError !== '' ? 'card-form-input__error' : ''
                  }
                  data-cy-input="firstname"
                  id="firstname"
                  onChange={handleFirstNameChange}
                  type="text"
                  value={firstName}
                />
                <FieldError errorMessage={firstNameError} />
              </label>
            </div>
            <div>
              <label htmlFor="lastname">
                <span className="">Last Name</span>
                <input
                  className={
                    lastNameError !== '' ? 'card-form-input__error' : ''
                  }
                  id="lastname"
                  data-cy-input="lastname"
                  onChange={handleLastNameChange}
                  type="text"
                  value={lastName}
                />
                <FieldError errorMessage={lastNameError} />
              </label>
            </div>
          </div>
          <label htmlFor="email">
            <div className="card-form-input__label-dual">
              <span className="">Company Email</span>
              <div className="card-form-input__note">
                You&apos;ll receive your report via email
              </div>
            </div>
            <input
              className={
                emailAddressError !== '' ? 'card-form-input__error' : ''
              }
              id="email"
              data-cy-input="email"
              onChange={handleEmailAddressChange}
              type="text"
              value={emailAddress}
            />
            <FieldError errorMessage={emailAddressError} />
          </label>
          <label htmlFor="company_facebook_page">
            <span className="">Facebook Page URL</span>
            <input
              className={
                facebookPageError !== '' ? 'card-form-input__error' : ''
              }
              id="company_facebook_page"
              data-cy-input="fb-page-url"
              onChange={handleFacebookPageChange}
              placeholder="https://www.facebook.com/nytimes"
              type="text"
              value={facebookPage}
            />
            <FieldError errorMessage={facebookPageError} />
          </label>
          <Timezones
            timezone={timezone}
            timezoneError={timezoneError}
            handleTimezoneChange={handleTimezoneChange}
          />
        </div>
        <div className="card-form-input__validation">
          <button
            className={`btn btn-blue btn-100 ${
              isSubmitting ? 'btn-loading' : ''
            }`}
            onClick={handleSubmit}
            type="button"
          >
            <span className="spinner" /> Generate my report
          </button>
          <FieldError errorMessage={generalError} />
        </div>
        <p className="card-form-input__privacy-policy">
          Your personal information is kept in accordance with our{' '}
          <a
            href="https://www.echobox.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <input name="utm_campaign" type="hidden" />
        <input name="utm_content" type="hidden" />
        <input name="utm_medium" type="hidden" />
        <input name="utm_source" type="hidden" />
        <input name="utm_term" type="hidden" />
      </form>
    </div>
  );
};

Form.propTypes = {
  handleFormSuccess: PropTypes.func.isRequired,
};

export default Form;
