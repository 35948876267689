/* eslint import/prefer-default-export: "off" */
/* eslint no-use-before-define: "off" */

export { getReportData };

const getReportData = (ratings, category) => {
  let data = null;
  try {
    ratings.forEach(rating => {
      if (rating.name.toLowerCase() === category.toLowerCase()) {
        data = rating;
      }
    });
  } catch (error) {
    //
  }
  return data;
};
