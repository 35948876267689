import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getDateFromUnix } from 'common/datetime';
import { isNull, isNullOrUndefined } from 'common/utility';

import facebookIcon from 'assets/img/ic-fb-dark.svg';
import linkedinIcon from 'assets/img/ic-ln-dark.svg';
import twitterIcon from 'assets/img/ic-tw-dark.svg';

const PageDetails = props => {
  const { name, image, url, likes, start, end, ratings } = props;

  document.title = `Facebook Grader - ${name}`;
  const pageUrl = url.replace(/\/\/$/, '/');
  const reportUrl = window.location.href.replace(
    /https?:\/\/localhost:[\d]+\/(.*)/,
    'https://facebookgrader.echobox.com/$1'
  );
  const encodedUrl = encodeURIComponent(reportUrl);
  document.head.querySelector('meta[property="og:url"]').content = reportUrl;
  const allRatings = ratings.filter(rating => rating.score !== null);
  let totalScore = 0;
  allRatings.forEach(rating => {
    if (!isNull(rating.score)) {
      totalScore += rating.score;
    }
  });
  const averageScore = Math.round(totalScore / allRatings.length);
  const reportMessage = `${name} scored ${averageScore} on the Facebook Grader. Does your page beat it?`;

  const startDate = getDateFromUnix(new Date(start) / 1000);
  const startFormatted = `${startDate.monthName} ${startDate.day}, ${startDate.year}`;
  const endDate = getDateFromUnix(new Date(end) / 1000);
  const endFormatted = `${endDate.monthName} ${endDate.day}, ${endDate.year}`;

  useEffect(() => {
    const links = document.getElementsByClassName('share-popup');
    Array.prototype.forEach.call(links, link => {
      link.addEventListener(
        'click',
        event => {
          event.preventDefault();
          const sharerUrl = event.currentTarget.href;
          let windowSize = 'width=585,height=511';
          const domain = sharerUrl.split('/')[2];
          switch (domain) {
            case 'www.facebook.com':
              windowSize = 'width=585,height=368';
              break;
            case 'www.twitter.com':
              windowSize = 'width=585,height=300';
              break;
            case 'www.linkedin.com':
              windowSize = 'width=517,height=511';
              break;
            default:
          }
          window.open(
            sharerUrl,
            '',
            `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,${windowSize}`
          );
          return false;
        },
        false
      );
    });
  }, []);

  return (
    <div className="card card-page">
      <div className="card-page__top">
        <div className="card-page__details">
          <img
            className="card-page__img-page"
            src={image}
            alt=""
            width="100px"
            height="100px"
          />
          <div>
            <h3>{name}</h3>
            <div className="card-page__page-link">
              <a href={pageUrl} target="_blank" rel="noopener noreferrer">
                {pageUrl}
              </a>
            </div>
          </div>
        </div>
        <div className="card-page__likes">
          <h3>{!isNullOrUndefined(likes) ? likes.toLocaleString() : ''}</h3>
          <div>Likes</div>
        </div>
      </div>
      <div className="card-page__bottom">
        <div>
          {startFormatted} - {endFormatted}
        </div>
        <div className="card-page__share-report">
          <span className="d-none">Share</span>
          <a
            href={`https://www.facebook.com/sharer.php?u=${encodedUrl}`}
            className="share-popup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="" />
          </a>
          <a
            href={`https://www.twitter.com/share?url=${encodedUrl}&text=${reportMessage}&hashtags=EchoboxHQ`}
            className="share-popup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterIcon} alt="" />
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`}
            className="share-popup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedinIcon} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

PageDetails.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  ratings: PropTypes.array.isRequired,
};

export default PageDetails;
