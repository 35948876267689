import React from 'react';
import PropTypes from 'prop-types';

import Clash from 'components/report/timing/Clash';
import Peak from 'components/report/timing/Peak';

import problemIcon from 'assets/img/ic-problem.svg';

import { ITEM_POSITIONS } from 'common/constants';

/* jscpd:ignore-start */
const Clashes = props => {
  const { problems, itemsToShow, handleShowMore } = props;

  return (
    <div className="share-timing-clashes">
      <div className="card-result__warning">
        <img src={problemIcon} alt="" width="44px" height="44px" />
        <span>Bad timing found</span>
        <img src={problemIcon} alt="" width="44px" height="44px" />
      </div>
      <div>
        {problems.map(
          (problem, index) =>
            index < itemsToShow && (
              <div
                className="share-timing-clashes__row"
                key={`clash-${
                  problem.firstShare
                    ? problem.firstShare.timestamp
                    : problem.secondShare.timestamp
                }`}
              >
                <Clash
                  position={ITEM_POSITIONS.LEFT}
                  share={problem.firstShare}
                />
                <Peak from={problem.firstShare} to={problem.secondShare} />
                <Clash
                  position={ITEM_POSITIONS.RIGHT}
                  share={problem.secondShare}
                />
              </div>
            )
        )}
        {itemsToShow < problems.length && (
          <button
            type="button"
            className="btn btn-loadmore"
            onClick={handleShowMore}
          >
            View all ({problems.length - itemsToShow} more)
          </button>
        )}
      </div>
    </div>
  );
};
Clashes.propTypes = {
  problems: PropTypes.array.isRequired,
  itemsToShow: PropTypes.number.isRequired,
  handleShowMore: PropTypes.func.isRequired,
};

export default Clashes;
/* jscpd:ignore-end */
