import React from 'react';
import PropTypes from 'prop-types';

const FieldError = props => {
  const { errorMessage } = props;

  if (errorMessage === '') {
    return null;
  }

  return <span className="card-form-input__error-text">{errorMessage}</span>;
};

FieldError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default FieldError;
