import React from 'react';
import PropTypes from 'prop-types';

import ScoreBar from 'components/report/ScoreBar';

const Scores = props => {
  const { ratings } = props;

  const scores = {};
  ratings.forEach(rating => {
    scores[rating.name.toLowerCase()] = rating.score;
  });
  scores.spacing = scores.spacing ?? null;
  scores.volume = scores.volume ?? null;
  scores.timing = scores.timing ?? null;

  return (
    <div className="card card-scores">
      <div className="card-scores__top">
        <div className="score-bar">
          <ScoreBar title="Share spacing" score={scores.spacing} />
          <div>Are you leaving sufficient time between posts?</div>
        </div>
        <div className="score-bar">
          <ScoreBar title="Share volume" score={scores.volume} />
          <div>Are you sharing content regularly enough?</div>
        </div>
        <div className="score-bar">
          <ScoreBar title="Share timing" score={scores.timing} />
          <div>Are you sharing at the optimal times?</div>
        </div>
      </div>
      <div className="card-scores__bottom">
        <div>Get more traffic from Facebook with Echobox</div>
        <a
          className="btn btn-blue"
          href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=MoreTraffic_button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find out how
        </a>
      </div>
    </div>
  );
};

Scores.propTypes = {
  ratings: PropTypes.array.isRequired,
};

export default Scores;
