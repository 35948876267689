import React from 'react';

const Footer = () => (
    <div className="card card-contact-us">
      <div className="card-contact-us__text">
        <h3>Want to learn more about Echobox?</h3>
        <p>
          Or have a question about this report? Please get in touch with our
          team.
        </p>
      </div>
      <a
        className="btn btn-white"
        href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=CTA_button"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact us
      </a>
    </div>
  );

export default Footer;
