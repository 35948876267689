import React from 'react';

import Header from 'components/Header';

const Loading = () => (
    <>
      <Header />
      <div className="card card-form-input card-loading-report">
        <div className="card-loading-report__header">
          <h2>Loading your report</h2>
          <span className="spinner" />
        </div>
      </div>
    </>
  );

export default Loading;
