export {
  getDateFromUnix,
  getFormattedDateFromUnix,
  getFormattedTimeFromUnix,
  getUnixTimestamp,
};

function getDateFromUnix(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const dayOfWeek = date.getDay();
  let dayName = '';
  switch (dayOfWeek) {
    case 0:
      dayName = 'Sunday';
      break;
    case 1:
      dayName = 'Monday';
      break;
    case 2:
      dayName = 'Tuesday';
      break;
    case 3:
      dayName = 'Wednesday';
      break;
    case 4:
      dayName = 'Thursday';
      break;
    case 5:
      dayName = 'Friday';
      break;
    case 6:
      dayName = 'Saturday';
      break;
    default:
  }

  let month = date.getMonth();
  let monthName = '';
  switch (month) {
    case 0:
      monthName = 'January';
      break;
    case 1:
      monthName = 'February';
      break;
    case 2:
      monthName = 'March';
      break;
    case 3:
      monthName = 'April';
      break;
    case 4:
      monthName = 'May';
      break;
    case 5:
      monthName = 'June';
      break;
    case 6:
      monthName = 'July';
      break;
    case 7:
      monthName = 'August';
      break;
    case 8:
      monthName = 'September';
      break;
    case 9:
      monthName = 'October';
      break;
    case 10:
      monthName = 'November';
      break;
    case 11:
      monthName = 'December';
      break;
    default:
  }
  month += 1;

  const year = date.getFullYear();

  // Work out if we need to adjust the result due to any time zone changes
  // that have occurred between now and the target date
  const startOfDay = new Date().setHours(0, 0, 0, 0);
  const timeZoneOffsets = {
    now: new Date(startOfDay).getTimezoneOffset() * 60 * 1000,
    then: date.getTimezoneOffset() * 60 * 1000,
  };
  const timeZoneDifference = timeZoneOffsets.then - timeZoneOffsets.now;
  const dateOffset = Math.floor(
    (date - startOfDay - timeZoneDifference) / (24 * 60 * 60 * 1000)
  );

  let slang = '';
  if (dateOffset === 0) {
    slang = 'Today';
  } else if (dateOffset === 1) {
    slang = 'Tomorrow';
  } else if (dateOffset === -1) {
    slang = 'Yesterday';
  } else {
    slang = '';
  }

  // Add leading zeros to minutes & hours if < 10
  let minuteZero = '';
  let hourZero = '';
  if (minute.toString().length < 2) {
    // Add leading zeros
    minuteZero = `0${minute}`;
  } else {
    minuteZero = minute.toString();
  }

  if (hour.toString().length < 2) {
    // Add leading zeros
    hourZero = `0${hour}`;
  } else {
    hourZero = hour.toString();
  }

  const dateObject = {
    hour,
    hourZero,
    minute,
    minuteZero,
    day,
    dayName,
    month,
    monthName,
    year,
    slang,
    dateOffset,
  };
  return dateObject;
}

function getFormattedDateFromUnix(timestamp) {
  const showTime = getUnixTimestamp() - timestamp < 30 * 24 * 60 * 60;

  let timeFormatted;
  const { timeDetail, ampm } = getFormattedTimeFromUnix(timestamp);
  timeDetail.ampm = ampm;

  const timeOrYear = showTime
    ? `${timeDetail.hourZero}:${timeDetail.minuteZero}${timeDetail.ampm}`
    : timeDetail.year;

  if (timeDetail.slang !== '') {
    timeFormatted = `${timeDetail.slang}, ${timeOrYear}`;
  } else {
    timeFormatted = `${timeDetail.monthName.substring(0, 3)} ${
      timeDetail.day
    }, ${timeOrYear}`;
  }

  return timeFormatted;
}

function getFormattedTimeFromUnix(timestamp) {
  const timeDetail = getDateFromUnix(timestamp);

  let ampm = '';
  timeDetail.hourZero = timeDetail.hour;
  // Check if am/pm setting switched on
  if (timeDetail.hour >= 12) {
    ampm = 'pm';
    if (timeDetail.hour > 12) {
      timeDetail.hourZero = timeDetail.hour - 12;
    }
  } else {
    ampm = 'am';
  }
  // Quick fix here for the first hour of the day which comes up as 00 when it should be 12
  if (timeDetail.slang !== '' && ampm === 'am' && timeDetail.hour === 0) {
    timeDetail.hourZero = '12';
  }

  return {
    timeDetail,
    ampm,
  };
}

function getUnixTimestamp(dateObj = new Date()) {
  return Math.round(dateObj.getTime() / 1000);
}
