import React from 'react';
import PropTypes from 'prop-types';

const IntroText = props => {
  const { clashes, score } = props;

  let message;
  if (score === null) {
    message = 'We could not analyze the data.';
  } else if (clashes === 0) {
    message = "We couldn't find any issues.";
  } else {
    message = `We found ${clashes} large share gap${clashes !== 1 ? 's' : ''}!`;
  }

  return <div className="copy__header">{message}</div>;
};

IntroText.propTypes = {
  clashes: PropTypes.number.isRequired,
  score: PropTypes.number,
};

IntroText.defaultProps = {
  score: null,
};

export default IntroText;
