import React from 'react';

import Header from 'components/Header';

import analyzingFailed from 'assets/img/img-analyzing-failed.svg';

const Invalid = () => (
    <>
      <Header />
      <div className="card card-form-input">
        <div className="">
          <div className="">
            <h2>Report could not be loaded</h2>
            <p>
              There is an issue with the URL, unfortunately we could not
              retrieve the report.
            </p>
            <img
              src={analyzingFailed}
              alt=""
              className="card-form-input__img-success"
            />
          </div>
        </div>
      </div>{' '}
    </>
  );

export default Invalid;
