/* eslint react-hooks/exhaustive-deps: "off" */

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Footer from 'components/report/Footer';
import Header from 'components/Header';
import Invalid from 'components/report/Invalid';
import Loading from 'components/report/Loading';
import Missing from 'components/report/Missing';
import PageDetails from 'components/report/PageDetails';
import ReportSpacing from 'components/report/ReportSpacing';
import ReportTiming from 'components/report/ReportTiming';
import ReportVolume from 'components/report/ReportVolume';
import Scores from 'components/report/Scores';

import { getServerAddress } from 'common/environment';
import { isNull } from 'common/utility';
import * as logger from 'common/logger';

const Report = props => {
  const PAGE_STATES = {
    INVALID: 'INVALID',
    LOADING: 'LOADING',
    MISSING: 'MISSING',
    SUCCESS: 'SUCCESS',
  };
  const [pageState, setPageState] = useState(PAGE_STATES.LOADING);
  const [reportData, setReportData] = useState(null);

  // Attempt to download data file from S3, show error page if this fails
  useEffect(() => {
    const fetchData = () => {
      const requestUrl = `${getServerAddress()}/data${props.location.search}`;
      fetch(requestUrl, { method: 'GET' })
        .then(dataResponse => {
          if (dataResponse.status === 422) {
            setPageState(PAGE_STATES.INVALID);
            return null;
          }
          if (dataResponse.status === 404 || dataResponse.status === 500) {
            setPageState(PAGE_STATES.MISSING);
            return null;
          }
          const streamReader = dataResponse.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return streamReader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return null;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        })
        .then(readableStream => new Response(readableStream))
        .then(responseStream => responseStream.json())
        .then(jsonResponse => {
          setPageState(PAGE_STATES.SUCCESS);
          setReportData(JSON.parse(jsonResponse));
        })
        .catch(error => {
          logger.log('Data Fetching Error', {
            ReportParams: props.location.search,
            FormResponse: logger.stringifyError(error),
          });
          setPageState(PAGE_STATES.INVALID);
        });
    };

    fetchData();
  }, []);

  if (pageState === PAGE_STATES.LOADING) {
    return <Loading />;
  }

  if (pageState === PAGE_STATES.INVALID) {
    return <Invalid />;
  }

  if (pageState === PAGE_STATES.MISSING) {
    return <Missing />;
  }

  if (isNull(reportData)) {
    return null;
  }

  return (
    <>
      <Header />
      {reportData.pages.map(page => (
        <Fragment key={`page-${page.name}`}>
          <PageDetails
            name={page.name}
            image={page.image}
            url={page.url}
            likes={page.likesCount}
            start={page.start}
            end={page.end}
            ratings={page.ratings}
          />
          <Scores ratings={page.ratings} />
          <ReportSpacing ratings={page.ratings} />
          <ReportVolume ratings={page.ratings} />
          <ReportTiming ratings={page.ratings} />
        </Fragment>
      ))}
      <Footer />
    </>
  );
};

Report.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Report.defaultProps = {
  location: {
    search: '',
  },
};

export default withRouter(Report);
