import React from 'react';
import PropTypes from 'prop-types';

const CallToAction = props => {
  const { className, colour, label, utm } = props;

  return (
    <a
      className={`btn btn-${colour} ${className}`}
      href={`https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=${utm}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

CallToAction.propTypes = {
  className: PropTypes.string,
  colour: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  utm: PropTypes.string.isRequired,
};

CallToAction.defaultProps = {
  className: '',
};

export default CallToAction;
