import React from 'react';
import PropTypes from 'prop-types';

import EmptyClash from 'components/report/timing/EmptyClash';

import { getFormattedDateFromUnix } from 'common/datetime';
import { isNull } from 'common/utility';

import { ITEM_POSITIONS } from 'common/constants';

/* jscpd:ignore-start */
const Clash = props => {
  const { position, share } = props;

  if (isNull(share)) {
    return <EmptyClash />;
  }

  const messageClass =
    position === ITEM_POSITIONS.LEFT
      ? ''
      : 'share-volume-clashes__share-message--right';
  const timeClass =
    position === ITEM_POSITIONS.LEFT
      ? 'share-volume-clashes__time--left'
      : 'share-volume-clashes__time--right';
  const imageStyle = position === ITEM_POSITIONS.RIGHT ? { order: 2 } : {};

  return (
    <a
      className={`share-volume-clashes__share-message ${messageClass}`}
      href={share.socialPostURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={share.imageUrl} alt="" style={imageStyle} />
      <div>
        <div className="share-spacing-clashes__share-message-text">
          {share.shareMessage}
        </div>
        <div className={`share-spacing-clashes__time ${timeClass}`}>
          <span>{getFormattedDateFromUnix(share.timestamp)}</span>
        </div>
      </div>
    </a>
  );
};
/* jscpd:ignore-end */

Clash.propTypes = {
  position: PropTypes.oneOf(Object.values(ITEM_POSITIONS)).isRequired,
  share: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    socialPostURL: PropTypes.string.isRequired,
    shareMessage: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }),
};

Clash.defaultProps = {
  share: null,
};

export default Clash;
