/* eslint react/jsx-filename-extension: "off" */

import React from 'react';
import ReactDOM from 'react-dom';

import FacebookGrader from 'FacebookGrader';

ReactDOM.render(
  <React.StrictMode>
    <FacebookGrader />
  </React.StrictMode>,
  document.getElementById('root')
);
