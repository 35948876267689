import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AdditionalText from 'components/report/AdditionalText';
import CallToAction from 'components/report/CallToAction';
import Clashes from 'components/report/volume/Clashes';
import IntroText from 'components/report/volume/IntroText';
import NoClashes from 'components/report/volume/NoClashes';
import ScoreBarWithIcon from 'components/report/ScoreBarWithIcon';

import { colourFromScore } from 'common/misc';
import { getReportData } from 'common/data';
import { MAXIMUM_SCORE } from 'common/constants';

/* jscpd:ignore-start */
const ReportVolume = props => {
  const { ratings } = props;

  const report = getReportData(ratings, 'VOLUME');

  const [itemsToShow, setItemsToShow] = useState(
    report.problems.length <= 4 ? report.problems.length : 3
  );

  const handleShowMore = () => {
    setItemsToShow(report.problems.length);
  };

  const ctaLabel =
    report.score === MAXIMUM_SCORE
      ? 'GET MORE ACCURATE RESULTS'
      : 'OPTIMIZE YOUR SHARE VOLUME';

  return (
    <div className="card card-result">
      <div className="card-result__top">
        <ScoreBarWithIcon
          category="volume"
          title="Share volume"
          score={report.score}
        />
        <CallToAction
          className="d-none"
          colour={colourFromScore(report.score)}
          label={ctaLabel}
          utm={
            report.score === MAXIMUM_SCORE
              ? 'ShareVolume_altbutton1'
              : 'ShareVolume_button1'
          }
        />
      </div>
      <div className="copy">
        <IntroText clashes={report.problems.length} score={report.score} />
        <div className="copy__body">
          If you leave long gaps between shares, you&apos;re missing out on
          traffic and engagement. Make the most of your audience on Facebook by
          sharing enough content, and by sharing regularly. Even resharing
          content can be a powerful way to keep your followers engaged.
        </div>
        <AdditionalText category="volume" ratings={ratings} />
      </div>
      {report.score === null || report.problems.length === 0 ? (
        <NoClashes />
      ) : (
        <Clashes
          problems={report.problems}
          itemsToShow={itemsToShow}
          handleShowMore={handleShowMore}
        />
      )}
      <div className="card-result__text-2">
        <div className="copy">
          <div className="copy__header">Our recommendation</div>
          <div className="copy__body">
            Ensure a constant flow of Facebook traffic by posting content
            regularly. If you don&apos;t have enough new content, try resharing
            older content, which can be effective to grow your Facebook
            audience.{' '}
            <a
              href="https://resources.echobox.com/white-papers/the-publishers-ultimate-guide-to-resharing-on-facebook?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareVolume_text2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read about resharing on Facebook to drive more traffic.
            </a>
          </div>
        </div>
        <div className="copy">
          <div className="copy__header">How can Echobox help?</div>
          <div className="copy__body">
            Echobox offers a range of tools to boost your content&apos;s
            visibility on Facebook and engage audiences when they’re most
            receptive. The Echobox Autofeed uses artificial intelligence to
            determine the optimal pace at which to share your content and can
            automate your shares throughout the day and during weekends. If you
            don’t have fresh content to share, Echobox can find and reshare your
            best content to keep audiences engaged at all times.
          </div>
        </div>
      </div>
      <div className="card-result__bottom">
        <CallToAction
          className="card-result__btn-bottom"
          colour={colourFromScore(report.score)}
          label={ctaLabel}
          utm={
            report.score === MAXIMUM_SCORE
              ? 'ShareVolume_altbutton3'
              : 'ShareVolume_button2'
          }
        />
      </div>
      <div className="copy-note">
        This analysis is based on an estimate of sharing patterns on your
        Facebook Page over the last 7 days. <br className="d-none" />
        For personalized and more accurate results,{' '}
        <a
          href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareVolume_text1"
          target="_blank"
          rel="noopener noreferrer"
        >
          use Echobox.
        </a>
      </div>
    </div>
  );
};

ReportVolume.propTypes = {
  ratings: PropTypes.array.isRequired,
};

export default ReportVolume;
/* jscpd:ignore-end */
