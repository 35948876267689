/* eslint no-use-before-define:"off" */

export {
  cloneArray,
  cloneObject,
  isDefined,
  isEmpty,
  isEmptyOrNullOrUndefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
};

function cloneArray(arr) {
  return JSON.parse(JSON.stringify(arr));
}

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function isDefined(value) {
  return !isUndefined(value);
}

function isEmpty(value) {
  if (typeof value === 'undefined') return true;
  if (value === null) return true;
  if (typeof value !== 'object') return false;
  return !Object.keys(value).length;
}

function isEmptyOrNullOrUndefined(value) {
  return isEmpty(value) || isNull(value) || isUndefined(value);
}

function isNull(value) {
  return value === null;
}

function isNullOrUndefined(value) {
  return isNull(value) || isUndefined(value);
}

function isUndefined(value) {
  return value === undefined || typeof value === 'undefined';
}
