import React from 'react';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

import Request from 'pages/Request';
import Report from 'pages/Report';

import 'assets/css/styles.css';

const FacebookGrader = props => {
  const history = createBrowserHistory(props);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/health" component={() => <div />} />
        <Route path="/report" component={() => <Report />} />
        <Route component={() => <Request />} />
      </Switch>
    </Router>
  );
};

export default FacebookGrader;
