import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AdditionalText from 'components/report/AdditionalText';
import CallToAction from 'components/report/CallToAction';
import Clashes from 'components/report/timing/Clashes';
import IntroText from 'components/report/timing/IntroText';
import NoClashes from 'components/report/timing/NoClashes';
import ScoreBarWithIcon from 'components/report/ScoreBarWithIcon';

import { colourFromScore } from 'common/misc';
import { getReportData } from 'common/data';
import { MAXIMUM_SCORE } from 'common/constants';

/* jscpd:ignore-start */
const ReportTiming = props => {
  const { ratings } = props;

  const report = getReportData(ratings, 'TIMING');

  const [itemsToShow, setItemsToShow] = useState(
    report.problems.length <= 4 ? report.problems.length : 3
  );

  const handleShowMore = () => {
    setItemsToShow(report.problems.length);
  };

  const ctaLabel =
    report.score === MAXIMUM_SCORE
      ? 'GET MORE ACCURATE RESULTS'
      : 'DISCOVER YOUR BEST TIMES TO POST';

  return (
    <div className="card card-result">
      <div className="card-result__top">
        <ScoreBarWithIcon
          category="timing"
          title="Share timing"
          score={report.score}
        />
        <CallToAction
          className="d-none"
          colour={colourFromScore(report.score)}
          label={ctaLabel}
          utm={
            report.score === MAXIMUM_SCORE
              ? 'ShareTiming_altbutton1'
              : 'ShareTiming_button1'
          }
        />
      </div>
      <div className="copy">
        <IntroText clashes={report.problems.length} score={report.score} />
        <div className="copy__body">
          When you don&apos;t share content during peak times - when a big part
          of your audience is on Facebook - you miss out on opportunities to
          grow your audience and traffic. The more followers see your content,
          the more engagement, reshares and clicks you get.
        </div>
        <AdditionalText category="timing" ratings={ratings} />
      </div>
      {report.score === null || report.problems.length === 0 ? (
        <NoClashes />
      ) : (
        <Clashes
          problems={report.problems}
          itemsToShow={itemsToShow}
          handleShowMore={handleShowMore}
        />
      )}
      <div className="card-result__text-2">
        <div className="copy">
          <div className="copy__header">Our recommendation</div>
          <div className="copy__body">
            Always keep an eye on when your audience is active and make sure to
            share content during peak times. Remember that the best time to post
            will be different for each publisher and can fluctuate depending on
            multiple factors including post topic, the audience and current
            trends. Be wary of one-size-fits-all post timing recommendations.{' '}
            <a
              href="https://resources.echobox.com/blog/when-is-the-best-time-to-post-on-facebook-2?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareTiming_text2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read about the best time to post on Facebook.
            </a>
          </div>
        </div>
        <div className="copy">
          <div className="copy__header">How can Echobox help?</div>
          <div className="copy__body">
            Echobox uses a publisher&apos;s historical data and other real-time
            inputs like audience behaviors and social media activity to
            calculate ideal share times. Every few seconds, our technology
            recalculates every possible posting sequence to determine the
            optimal share times to increase your traffic. With Echobox, each
            share is published at the best possible minute of the day.
          </div>
        </div>
      </div>
      <div className="card-result__bottom">
        <CallToAction
          className="card-result__btn-bottom"
          colour={colourFromScore(report.score)}
          label={ctaLabel}
          utm={
            report.score === MAXIMUM_SCORE
              ? 'ShareTiming_altbutton3'
              : 'ShareTiming_button2'
          }
        />
      </div>
      <div className="copy-note">
        This analysis is based on an estimate of sharing patterns on your
        Facebook Page over the last 7 days. <br className="d-none" />
        For personalized and more accurate results,{' '}
        <a
          href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=CTA_button"
          target="_blank"
          rel="noopener noreferrer"
        >
          use Echobox.
        </a>
      </div>
    </div>
  );
};

ReportTiming.propTypes = {
  ratings: PropTypes.array.isRequired,
};

export default ReportTiming;
/* jscpd:ignore-end */
