import React from 'react';
import PropTypes from 'prop-types';

import ScoreBar from 'components/report/ScoreBar';

import { colourFromScore } from 'common/misc';

import spacingBlue from 'assets/img/ic-share-spacing-blue.svg';
import spacingOrange from 'assets/img/ic-share-spacing-orange.svg';
import spacingRed from 'assets/img/ic-share-spacing-red.svg';
import timingBlue from 'assets/img/ic-share-timing-blue.svg';
import timingOrange from 'assets/img/ic-share-timing-orange.svg';
import timingRed from 'assets/img/ic-share-timing-red.svg';
import volumeBlue from 'assets/img/ic-share-volume-blue.svg';
import volumeOrange from 'assets/img/ic-share-volume-orange.svg';
import volumeRed from 'assets/img/ic-share-volume-red.svg';

const ScoreBarWithIcon = props => {
  const { category, title, score } = props;

  const icons = {
    spacing: {
      blue: spacingBlue,
      orange: spacingOrange,
      red: spacingRed,
    },
    volume: {
      blue: volumeBlue,
      orange: volumeOrange,
      red: volumeRed,
    },
    timing: {
      blue: timingBlue,
      orange: timingOrange,
      red: timingRed,
    },
  };

  const colour = colourFromScore(score);
  const icon = icons[category][colour];

  return (
    <div className="score-icon-bar">
      <img src={icon} alt="" />
      <div className="score-bar">
        <ScoreBar title={title} score={score} />
      </div>
    </div>
  );
};

ScoreBarWithIcon.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

export default ScoreBarWithIcon;
