import React from 'react';
import PropTypes from 'prop-types';

import { getReportData } from 'common/data';
import { MAXIMUM_SCORE } from 'common/constants';

const AdditionalText = props => {
  const { category, ratings } = props;

  const spacing = getReportData(ratings, 'SPACING');
  const volume = getReportData(ratings, 'VOLUME');
  const timing = getReportData(ratings, 'TIMING');

  // Blame https://docs.google.com/document/d/1aa9BG6_9NYo5S4O2pwN6MgtXPJX97tCFoGHvuSxEnxY/edit for the following...

  let message = null;
  let showLink = false;

  // Treat a lack of problem items as being the same as an item having MAXIMUM_SCORE (95%)
  if (spacing.problems.length === 0) {
    spacing.score = MAXIMUM_SCORE;
  }
  if (volume.problems.length === 0) {
    volume.score = MAXIMUM_SCORE;
  }
  if (timing.problems.length === 0) {
    timing.score = MAXIMUM_SCORE;
  }

  if (spacing.score === MAXIMUM_SCORE && volume.score === MAXIMUM_SCORE && timing.score === MAXIMUM_SCORE) {
    showLink = true;
    switch (category) {
      case 'spacing':
        message =
          "It looks like your shares were adequately spaced over the last 7 days. However, this analysis is only based on aggregate data from a limited period. Remember that the optimal spacing of your Facebook shares constantly fluctuates depending on many real-time factors, including content streams, audience behaviors and Facebook's algorithms.";
        break;
      case 'volume':
        message =
          "It looks like you had sufficient share volume over the last 7 days. However, this analysis is only based on aggregate data from a limited period. Remember that your optimal share volume can change constantly, depending on many real-time factors like content streams, audience behaviors and Facebook's algorithms.";
        break;
      case 'timing':
        message =
          "It looks like you shared your content at the right times over the last 7 days. However, this analysis is only based on aggregate data from a limited period. Remember that the best time to post your content can differ even from minute to minute, depending on many real-time factors like content streams, audience behaviors and Facebook's algorithms.";
        break;
      default:
    }
  }

  if (spacing.score === MAXIMUM_SCORE && volume.score < MAXIMUM_SCORE && timing.score < MAXIMUM_SCORE) {
    switch (category) {
      case 'spacing':
        message =
          'It looks like your shares were adequately spaced over the last 7 days. But are you posting enough content, and sharing at the right time? Though your shares are sufficiently spaced, your share volume and share timing could be improved!';
        break;
      case 'volume':
        break;
      case 'timing':
        break;
      default:
    }
  }

  if (spacing.score === MAXIMUM_SCORE && volume.score === MAXIMUM_SCORE && timing.score < MAXIMUM_SCORE) {
    switch (category) {
      case 'spacing':
        message =
          'It looks like your shares were adequately spaced over the last 7 days. But are you sharing content at the right time? Though your shares are sufficiently spaced, your share timing could be improved!';
        break;
      case 'volume':
        message =
          "It looks like you had sufficient share volume over the last 7 days. But are you posting at the right time of day to maximize your visibility and traffic? Though you're sharing enough content, your share timing could be improved";
        break;
      case 'timing':
        break;
      default:
    }
  }

  if (spacing.score === MAXIMUM_SCORE && volume.score < MAXIMUM_SCORE && timing.score === MAXIMUM_SCORE) {
    switch (category) {
      case 'spacing':
        message =
          'It looks like your shares were adequately spaced over the last 7 days. But are you sharing enough content on a regular basis? Though your shares are sufficiently spaced, your share volume could be improved!';
        break;
      case 'volume':
        break;
      case 'timing':
        message =
          'It looks like you shared your content at the right times over the last 7 days. But are you sharing the right volume of posts? Though your share timing is good, your share volume could be improved!';
        break;
      default:
    }
  }

  if (spacing.score < MAXIMUM_SCORE && volume.score === MAXIMUM_SCORE && timing.score < MAXIMUM_SCORE) {
    switch (category) {
      case 'spacing':
        break;
      case 'volume':
        message =
          "It looks like you had sufficient share volume over the last 7 days. But are you posting at the right frequency to avoid penalization? Are you posting at the right time of day to maximize your visibility and traffic? Though you're sharing enough content, your share spacing and timing could both be improved!";
        break;
      case 'timing':
        break;
      default:
    }
  }

  if (spacing.score < MAXIMUM_SCORE && volume.score === MAXIMUM_SCORE && timing.score === MAXIMUM_SCORE) {
    switch (category) {
      case 'spacing':
        break;
      case 'volume':
        message =
          "It looks like you had sufficient share volume over the last 7 days. But are you posting at the right frequency to avoid penalization? Though you're sharing enough content, your share spacing could be improved!";
        break;
      case 'timing':
        message =
          'It looks like you shared your content at the right times over the last 7 days. But are you posting at the right frequency to avoid penalization? Though your share timing is good, your share spacing could be improved!';
        break;
      default:
    }
  }

  if (spacing.score < MAXIMUM_SCORE && volume.score < MAXIMUM_SCORE && timing.score === MAXIMUM_SCORE) {
    switch (category) {
      case 'spacing':
        break;
      case 'volume':
        break;
      case 'timing':
        message =
          'It looks like you shared your content at the right times over the last 7 days. But are you posting at the right frequency to avoid penalization, and are you sharing enough content? Though your share timing is good, your share spacing and volume could both be improved!';
        break;
      default:
    }
  }

  if (message === null) {
    return null;
  }

  return (
    <div className="notification-100">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ minWidth: '16px', position: 'relative', top: '4px' }}
      >
        <path
          d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.8 12H7.2V7.2H8.8V12ZM8.8 5.6H7.2V4H8.8V5.6Z"
          fill="#4285FF"
        />
      </svg>
      <div>
        {message}{' '}
        {showLink && (
          <>
            For the most accurate results,
            <a
              href="https://www.echobox.com/contact-sales/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              try Echobox.
            </a>
          </>
        )}
      </div>
    </div>
  );
};

AdditionalText.propTypes = {
  category: PropTypes.string.isRequired,
  ratings: PropTypes.array.isRequired,
};

export default AdditionalText;
