import React from 'react';

import clashIcon from 'assets/img/ic-clash.svg';

/* jscpd:ignore-start */
const Row = () => (
  <div className="share-volume-clashes__row">
    <div className="share-volume-clashes__share-message">
      <img src="https://placeimg.com/100/100/any" alt="" />
      <div>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
          quibusdam quaerat reprehenderit.
        </div>
        <div className="share-spacing-clashes__time share-spacing-clashes__time--left">
          <span>Jun 19, 3:40pm</span>
        </div>
      </div>
    </div>
    <div className="share-volume-clashes__clash">
      <div className="share-volume-clashes__clash-text">
        <svg
          width="64"
          height="16"
          viewBox="0 0 64 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.292893 7.29289C-0.097631 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538408 7.04738 0.538408 6.65685 0.928933L0.292893 7.29289ZM63 9.00001C63.5523 9.00001 64 8.55229 64 8.00001C64 7.44772 63.5523 7.00001 63 7.00001L63 9.00001ZM1 9L63 9.00001L63 7.00001L1 7L1 9Z"
            fill="#F5134A"
          />
        </svg>
        <span>3 hours gap</span>
        <svg
          width="64"
          height="16"
          viewBox="0 0 64 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M63.7071 7.29289C64.0976 7.68342 64.0976 8.31658 63.7071 8.70711L57.3431 15.0711C56.9526 15.4616 56.3195 15.4616 55.9289 15.0711C55.5384 14.6805 55.5384 14.0474 55.9289 13.6569L61.5858 8L55.9289 2.34315C55.5384 1.95262 55.5384 1.31946 55.9289 0.928933C56.3195 0.538408 56.9526 0.538408 57.3431 0.928933L63.7071 7.29289ZM1 9.00001C0.447719 9.00001 3.86298e-06 8.55229 3.8147e-06 8.00001C3.76641e-06 7.44772 0.447719 7.00001 1 7.00001L1 9.00001ZM63 9L1 9.00001L1 7.00001L63 7L63 9Z"
            fill="#F5134A"
          />
        </svg>
      </div>
    </div>
    <div className="share-volume-clashes__share-message share-volume-clashes__share-message--right">
      <img src="https://placeimg.com/100/100/any" alt="" />
      <div>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
          quibusdam quaerat reprehenderit.
        </div>
        <div className="share-spacing-clashes__time share-spacing-clashes__time--left">
          <span>Jun 19, 3:40pm</span>
        </div>
      </div>
    </div>
  </div>
);

const NoClashes = () => (
  <div className="position-relative">
    <a
      className="btn btn-blue btn-blur-background"
      href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareVolume_altbutton2"
      target="_blank"
      rel="noopener noreferrer"
    >
      GET MORE ACCURATE RESULTS
    </a>
    <div className="share-volume-clashes blur">
      <div className="card-result__warning">
        <img src={clashIcon} alt="" width="44px" height="44px" />
        <span>Share gaps found</span>
        <img src={clashIcon} alt="" width="44px" height="44px" />
      </div>
      <div>
        <Row />
        <Row />
        <Row />
        <button type="button" className="btn btn-loadmore">
          View all
        </button>
      </div>
    </div>
  </div>
);
/* jscpd:ignore-end */

export default NoClashes;
