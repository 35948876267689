import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AdditionalText from 'components/report/AdditionalText';
import CallToAction from 'components/report/CallToAction';
import Clashes from 'components/report/spacing/Clashes';
import IntroText from 'components/report/spacing/IntroText';
import NoClashes from 'components/report/spacing/NoClashes';
import ScoreBarWithIcon from 'components/report/ScoreBarWithIcon';

import { colourFromScore } from 'common/misc';
import { getReportData } from 'common/data';
import { MAXIMUM_SCORE } from 'common/constants';

/* jscpd:ignore-start */
const ReportSpacing = props => {
  const { ratings } = props;

  const report = getReportData(ratings, 'SPACING');

  const [itemsToShow, setItemsToShow] = useState(
    report.problems.length <= 4 ? report.problems.length : 3
  );

  const handleShowMore = () => {
    setItemsToShow(report.problems.length);
  };

  const ctaLabel =
    report.score === MAXIMUM_SCORE
      ? 'GET MORE ACCURATE RESULTS'
      : 'IMPROVE YOUR SHARE SPACING';

  return (
    <div className="card card-result">
      <div className="card-result__top">
        <ScoreBarWithIcon
          category="spacing"
          title="Share spacing"
          score={report.score}
        />
        <CallToAction
          className="d-none"
          colour={colourFromScore(report.score)}
          label={ctaLabel}
          utm={
            report.score === MAXIMUM_SCORE
              ? 'ShareSpace_altbutton1'
              : 'ShareSpace_button1'
          }
        />
      </div>
      <div className="copy">
        <IntroText clashes={report.problems.length} score={report.score} />
        <div className="copy__body">
          When you don&apos;t leave sufficient time between your posts, Facebook
          penalizes you. This means Facebook will not display your posts as
          often in the News Feed, resulting in reduced reach and traffic for
          your posts.
        </div>
        <AdditionalText category="spacing" ratings={ratings} />
      </div>
      {report.score === null || report.problems.length === 0 ? (
        <NoClashes />
      ) : (
        <Clashes
          problems={report.problems}
          itemsToShow={itemsToShow}
          handleShowMore={handleShowMore}
        />
      )}
      <div className="card-result__text-2">
        <div className="copy">
          <div className="copy__header">Our recommendation</div>
          <div className="copy__body">
            Only post shares close together in exceptional circumstances (for
            instance, if you&apos;re sharing time-sensitive content that
            can&apos;t wait, or if you&apos;re expecting the additional traffic
            of peak times to outweigh Facebook&apos;s penalization).{' '}
            <a
              href="https://resources.echobox.com/white-papers/facebooks-penalization-algorithm-revealed?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareSpace_text2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read about oversharing and Facebook penalization.
            </a>
          </div>
        </div>
        <div className="copy">
          <div className="copy__header">How can Echobox help?</div>
          <div className="copy__body">
            Echobox optimizes share times with our proprietary technology to
            reverse-engineer Facebook&apos;s penalization algorithm. We also use
            your historical data and other real-time inputs like audience
            behaviors and social media activity to calculate ideal sharing
            frequencies. Echobox then prevents share clashes that are known to
            be detrimental to social performance.
          </div>
        </div>
      </div>
      <div className="card-result__bottom">
        <CallToAction
          className="card-result__btn-bottom"
          colour={colourFromScore(report.score)}
          label={ctaLabel}
          utm={
            report.score === MAXIMUM_SCORE
              ? 'ShareSpace_altbutton3'
              : 'ShareSpace_button2'
          }
        />
      </div>
      <div className="copy-note">
        This analysis is based on an estimate of sharing patterns on your
        Facebook Page over the last 7 days. <br className="d-none" />
        For personalized and more accurate results,{' '}
        <a
          href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareSpace_text1"
          target="_blank"
          rel="noopener noreferrer"
        >
          use Echobox.
        </a>
      </div>
    </div>
  );
};

ReportSpacing.propTypes = {
  ratings: PropTypes.array.isRequired,
};

export default ReportSpacing;
/* jscpd:ignore-end */
