import React from 'react';
import PropTypes from 'prop-types';

import EmptyClash from 'components/report/spacing/EmptyClash';

import { getFormattedDateFromUnix } from 'common/datetime';
import { isNull } from 'common/utility';

import { ITEM_POSITIONS } from 'common/constants';

const Clash = props => {
  const { position, share } = props;

  if (isNull(share)) {
    return <EmptyClash />;
  }

  const messageClass =
    position === ITEM_POSITIONS.LEFT
      ? 'share-spacing-clashes__share-message--left'
      : '';
  const timeClass =
    position === ITEM_POSITIONS.LEFT
      ? 'share-spacing-clashes__time--left'
      : 'share-spacing-clashes__time--right';
  const imageStyle = position === ITEM_POSITIONS.LEFT ? { order: 2 } : {};

  return (
    <a
      className={`share-spacing-clashes__share-message ${messageClass}`}
      href={share.socialPostURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={share.imageUrl} alt="" style={imageStyle} />
      <div>
        <div className="share-spacing-clashes__share-message-text">
          {share.shareMessage}
        </div>
        <div className={`share-spacing-clashes__time ${timeClass}`}>
          {position === ITEM_POSITIONS.LEFT && (
            <span>{getFormattedDateFromUnix(share.timestamp)}</span>
          )}
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H19L9.5 0L0 16ZM10.3636 13.4737H8.63636V11.7895H10.3636V13.4737ZM10.3636 10.1053H8.63636V6.73684H10.3636V10.1053Z"
              fill="#F5134A"
            />
          </svg>
          {position === ITEM_POSITIONS.RIGHT && (
            <span>{getFormattedDateFromUnix(share.timestamp)}</span>
          )}
        </div>
      </div>
    </a>
  );
};

Clash.propTypes = {
  position: PropTypes.oneOf(Object.values(ITEM_POSITIONS)).isRequired,
  share: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    socialPostURL: PropTypes.string.isRequired,
    shareMessage: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }),
};

Clash.defaultProps = {
  share: null,
};

export default Clash;
