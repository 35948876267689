import React from 'react';

import clashIcon from 'assets/img/ic-clash.svg';

const Row = () => (
  <div className="share-timing-clashes__row">
    <div className="share-timing-clashes__share-message">
      <img src="https://placeimg.com/100/100/any" alt="" />
      <div>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
          quibusdam quaerat reprehenderit.
        </div>
        <div className="share-spacing-clashes__time share-spacing-clashes__time--left">
          <span>Jun 19, 3:40pm</span>
        </div>
      </div>
    </div>
    <div className="share-timing-clashes__clash">
      <div className="share-timing-clashes__clash-text">
        <span>1pm</span>
        <div className="share-timing-clashes__clash-warning">
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H19L9.5 0L0 16ZM10.3636 13.4737H8.63636V11.7895H10.3636V13.4737ZM10.3636 10.1053H8.63636V6.73684H10.3636V10.1053Z"
              fill="#F5134A"
            />
          </svg>
          <span>Peak time missed</span>
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16H19L9.5 0L0 16ZM10.3636 13.4737H8.63636V11.7895H10.3636V13.4737ZM10.3636 10.1053H8.63636V6.73684H10.3636V10.1053Z"
              fill="#F5134A"
            />
          </svg>
        </div>
        <span>3pm</span>
      </div>
    </div>
    <div className="share-timing-clashes__share-message share-timing-clashes__share-message--right">
      <img src="https://placeimg.com/100/100/any" alt="" />
      <div>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
          quibusdam quaerat reprehenderit.
        </div>
        <div className="share-spacing-clashes__time share-spacing-clashes__time--left">
          <span>Jun 19, 3:40pm</span>
        </div>
      </div>
    </div>
  </div>
);

const NoClashes = () => (
  <div className="position-relative">
    <a
      className="btn btn-blue btn-blur-background"
      href="https://www.echobox.com/contact-sales?utm_source=website&utm_medium=LeadMagnet&utm_campaign=WS_FacebookGrader&utm_content=FacebookGrader1&utm_term=ShareTiming_altbutton2"
      target="_blank"
      rel="noopener noreferrer"
    >
      GET MORE ACCURATE RESULTS
    </a>
    <div className="share-timing-clashes blur">
      <div className="card-result__warning">
        <img src={clashIcon} alt="" width="44px" height="44px" />
        <span>Bad timing found</span>
        <img src={clashIcon} alt="" width="44px" height="44px" />
      </div>
      <div>
        <Row />
        <Row />
        <Row />
        <button type="button" className="btn btn-loadmore">
          View all
        </button>
      </div>
    </div>
  </div>
);

export default NoClashes;
