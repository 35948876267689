import React, { useState } from 'react';

import Form from 'components/request/Form';
import Header from 'components/Header';
import Success from 'components/request/Success';

const Request = () => {
  const [showForm, setShowForm] = useState(true);

  const handleFormSuccess = () => {
    setShowForm(false);
  };

  return (
    <>
      <Header />
      {showForm && <Form handleFormSuccess={handleFormSuccess} />}
      {!showForm && <Success />}
    </>
  );
};

export default Request;
